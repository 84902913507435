

import { mapActions } from "vuex";

export default {
    data: () => ({
        snackbar: false,
        text: '',
        ldn: false,
        timeout: 2000,
        validCad: true,
        show2: false,
        show3: false,
        loading: false,
        error_email: false,
        verification_email: false,
        redes_sociais: true,
        cadastro: true,
        email_text: "",
        confirmPassword: "",
        formCad: {
            email: "",
            password: "",
            name: "",
        },



        nomeRegras: [(v) => !!v || "Nome é obrigatório"],
        senhaRules1: [
            (v) => !!v || "Senha é obrigatória",
            (v) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) || 'A senha deve conter letras maiúsculas,  minúsculas, números e caracteres especiais(@,$,&...)',
        ],
        confirmPasswordRules: [
            (v) => !!v || "Senha é obrigatória",
            (v) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) || 'A senha deve conter letras maiúsculas,  minúsculas, números e caracteres especiais(@,$,&...)',
            // (v) => v.length >= 8 || "Minimo de 8 caracteres",
        ],

        emailRegras: [
            (v) => !!v || "E-mail é obrigatório",
            (v) => /.+@.+\..+/.test(v) || "Insira um e-mail válido",
        ],
    }),

    methods: {
        ...mapActions("auth", ["ActionRegister"]),
        async submit() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.cadastro = false;
                try {
                    const data = await this.ActionRegister(this.formCad);
                    console.log(data)
                    /*
                    this.text = data.data.message;
                    this.snackbar = true
                    */
                    this.$swal({
                        toast: false,
                        title: "Cadastro efetuado com sucesso",
                        icon: 'success',
                        confirmButtonColor: "#2778c4",onsStyling: false,
              
                        text: "Foi enviado para seu e-mail um link de verificação para conclusão do cadastro"
                    })


                    this.$router.push({ name: "login" });


                } catch (error) {
                    if (error.status == 400) {
                        this.formCad.email = "";
                        this.text = " Este e-mail já existe"
                        this.snackbar = true

                    }
                    this.loading = false;
                    this.cadastro = true;
                }
            }
        },

        voltar_registro() {
            this.redes_sociais = true;
            this.verification_email = false;
            this.loading = false;
            this.email_text = "";
            this.cadastro = true;
        },
        login() {

            this.$router.push({ name: "login" });
        }
    },
    computed: {
        passwordConfirmationRule() {
            return () =>
                this.formCad.password === this.confirmPassword ||
                "As senhas devem ser iguais";
        },
    },
};